import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery} from 'gatsby';
import Layout from '../layouts/index';
import translationsEn from '../locales/en.json';
import translationsEs from '../locales/es.json';

const MyInfo = () => {

  const [user, setUser] = useState(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const storedUser = localStorage.getItem('user');
      setUser(storedUser ? JSON.parse(storedUser) : null);
    }
  }, []);
  
  const [locale, setLocale] = useState('es');

  useEffect(() => {
    if (typeof window !== "undefined") {
      const storedLocale = localStorage.getItem('locale') || 'es';
      setLocale(storedLocale);
    }

    const handleLanguageChange = (event) => {
      setLocale(event.detail);
    };

    if (typeof window !== "undefined") {
      window.addEventListener('languageChanged', handleLanguageChange);
    }
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener('languageChanged', handleLanguageChange);
      }
    };
  }, []);

  const data = useStaticQuery(graphql`
    query {
      site: datoCmsSite {
        faviconMetaTags {
          tags
        }
        globalSeo {
          siteName
        }
      }
    }
  `);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
  
    if (name === "confirmPassword") {
      setUser(prevState => ({
        ...prevState,
        confirmPassword: value,
      }));
    } else {
      const [fieldName, subField] = name.split('.');
      
      if(subField) {
        setUser(prevState => ({
          ...prevState,
          [fieldName]: {
            ...prevState[fieldName],
            [subField]: value
          }
        }));
      } else {
        setUser(prevState => ({
          ...prevState,
          [name]: value
        }));
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    localStorage.setItem('user', JSON.stringify(user));
    // Potencialmente, mostrar un mensaje de éxito o redirigir
  };

  if (!user) return <div>Loading...</div>;

  const translations = locale === 'es' ? translationsEs : translationsEn;

  return (
    <Layout
      site={data.site}
      seo={{ ...data.site.globalSeo, ...data.site.faviconMetaTags }}
      lang={locale}
      title={"Mi cuenta - OS DO CASAL"}
      description={"Mi cuenta"}
      noIndex={true}
    >
      <div className="My-info">
        <h2 className="My-info__name">{translations['my-info']}</h2>
        <form onSubmit={handleSubmit} className="form-container">
          <div className="input-container">
            <label htmlFor="name">{translations['name']}</label>
            <input type="text" id="name" name="name" value={user.name || ''} onChange={handleInputChange} />
          </div>
          <div className="input-container">
            <label htmlFor="email">{translations['email']}</label>
            <input type="email" id="email" name="email" value={user.email || ''} onChange={handleInputChange} />
          </div>
          <div className="input-container">
            <label htmlFor="password">{translations['password']}</label>
            <input type="password" id="password" name="password" value={user.password || ''} onChange={handleInputChange} />
          </div>
          <div className="input-container">
            <label htmlFor="confirmPassword">{translations['confirm-password']}</label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={user.confirmPassword || ''}
              onChange={handleInputChange}
            />
          </div>
          <div className="address">
            <h3>{translations['shipping-address']}</h3>
            <div className="input-container">
              <label htmlFor="shippingAddress.street">{translations['street']}</label>
              <input type="text" id="shippingAddress.street" name="shippingAddress.street" value={user.shippingAddress.street || ''} onChange={handleInputChange} />
            </div>
            <div className="input-container">
              <label htmlFor="shippingAddress.addressNumber">{translations['address-number']}</label>
              <input type="text" id="shippingAddress.addressNumber" name="shippingAddress.addressNumber" value={user.shippingAddress.addressNumber || ''} onChange={handleInputChange} />
            </div>
            <div className="input-container">
              <label htmlFor="shippingAddress.door">{translations['door']}</label>
              <input type="text" id="shippingAddress.door" name="shippingAddress.door" value={user.shippingAddress.door || ''} onChange={handleInputChange} />
            </div>
            <div className="input-container">
              <label htmlFor="shippingAddress.city">{translations['city']}</label>
              <input type="text" id="shippingAddress.city" name="shippingAddress.city" value={user.shippingAddress.city || ''} onChange={handleInputChange} />
            </div>
            <div className="input-container">
              <label htmlFor="shippingAddress.state">{translations['state']}</label>
              <input type="text" id="shippingAddress.state" name="shippingAddress.state" value={user.shippingAddress.state || ''} onChange={handleInputChange} />
            </div>
            <div className="input-container">
              <label htmlFor="shippingAddress.zipCode">{translations['zipcode']}</label>
              <input type="text" id="shippingAddress.zipCode" name="shippingAddress.zipCode" value={user.shippingAddress.zipCode || ''} onChange={handleInputChange} />
            </div>
            <div className="input-container">
              <label htmlFor="shippingAddress.country">{translations['country']}</label>
              <input type="text" id="shippingAddress.country" name="shippingAddress.country" value={user.shippingAddress.country || ''} onChange={handleInputChange} />
            </div>
          </div>
          {user.billingAddress && (
            <>
              <div className="address">
                <h3>{translations['billing-address']}</h3>
                <div className="input-container">
                  <label htmlFor="billingAddress.street">{translations['street']}</label>
                  <input type="text" id="billingAddress.street" name="billingAddress.street" value={user.billingAddress.street || ''} onChange={handleInputChange} />
                </div>
                <div className="input-container">
                  <label htmlFor="billingAddress.addressNumber">{translations['address-number']}</label>
                  <input type="text" id="billingAddress.addressNumber" name="billingAddress.addressNumber" value={user.billingAddress.addressNumber || ''} onChange={handleInputChange} />
                </div>
                <div className="input-container">
                  <label htmlFor="billingAddress.door">{translations['door']}</label>
                  <input type="text" id="billingAddress.door" name="billingAddress.door" value={user.billingAddress.door || ''} onChange={handleInputChange} />
                </div>
                <div className="input-container">
                  <label htmlFor="billingAddress.city">{translations['city']}</label>
                  <input type="text" id="billingAddress.city" name="billingAddress.city" value={user.billingAddress.city || ''} onChange={handleInputChange} />
                </div>
                <div className="input-container">
                  <label htmlFor="billingAddress.state">{translations['state']}</label>
                  <input type="text" id="billingAddress.state" name="billingAddress.state" value={user.billingAddress.state || ''} onChange={handleInputChange} />
                </div>
                <div className="input-container">
                  <label htmlFor="billingAddress.zipCode">{translations['zipcode']}</label>
                  <input type="text" id="billingAddress.zipCode" name="billingAddress.zipCode" value={user.billingAddress.zipCode || ''} onChange={handleInputChange} />
                </div>
                <div className="input-container">
                  <label htmlFor="billingAddress.country">{translations['country']}</label>
                  <input type="text" id="billingAddress.country" name="billingAddress.country" value={user.billingAddress.country || ''} onChange={handleInputChange} />
                </div>
              </div>
            </>
          )}
          <button type="submit" className="submit-button">{translations['save']}</button>
        </form>
      </div>
    </Layout>
  );
};

export default MyInfo;